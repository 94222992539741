// // LoginContext.js
// import React, { createContext, useContext, useState } from 'react';

// const LogInContext = createContext();

// export const LogInStateProvider = ({ children }) => {
//     const [loggedIn, setLoggedIn] = useState(false); 
//     return (
//         <LogInContext.Provider value={{ loggedIn, setLoggedIn }}>
//             {children}
//         </LogInContext.Provider>
//     );
// };

// export const useLoginState = () => {
//     return useContext(LogInContext);
// };

import React, { createContext, useContext, useState, useEffect } from 'react';

export const LoginStateContext = createContext();

export const LogInStateProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setLoggedIn(true);
    }
  }, []);

  const login = (token) => {
    localStorage.setItem('token', token);
    setLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setLoggedIn(false);
  };

  return (
    <LoginStateContext.Provider value={{ loggedIn, login, logout ,setLoggedIn}}>
      {children}
      
    </LoginStateContext.Provider>
  );
};

export const useLoginState = () => {
  return useContext(LoginStateContext);
};
